import React, { useState } from "react";
import "./SecuritySettings.css";

const SecuritySettings = () => {
  const [securitySettings, setSecuritySettings] = useState({
    twoFA: false, // 2FA habilitado/desabilitado
    connectedDevices: ["iPhone 14", "MacBook Pro", "Samsung Galaxy"], // Dispositivos conectados
    securityQuestion: "",
    pin: "",
    activityHistory: [
      { id: 1, action: "Login realizado", date: "2023-12-01 10:00" },
      { id: 2, action: "Alteração de senha", date: "2023-11-25 15:45" },
    ],
  });

  const toggle2FA = () => {
    setSecuritySettings((prev) => ({
      ...prev,
      twoFA: !prev.twoFA,
    }));
  };

  const handleRemoveDevice = (device) => {
    setSecuritySettings((prev) => ({
      ...prev,
      connectedDevices: prev.connectedDevices.filter((d) => d !== device),
    }));
  };

  const handleSaveSettings = () => {
    console.log("Configurações de segurança salvas:", securitySettings);
    // Aqui você pode fazer a integração com a API para salvar as configurações
  };

  return (
    <div className="security-settings-container">
      <h2>Configurações de Segurança</h2>

      {/* 2FA */}
      <div className="security-section">
        <h3>Autenticação em Duas Etapas (2FA)</h3>
        <label className="switch">
          <input
            type="checkbox"
            checked={securitySettings.twoFA}
            onChange={toggle2FA}
          />
          <span className="slider"></span>
        </label>
        <p>
          {securitySettings.twoFA
            ? "2FA está ativado. Você precisará de um código adicional para entrar."
            : "2FA está desativado. Recomendamos ativá-lo para maior segurança."}
        </p>
      </div>

      {/* Dispositivos conectados */}
      <div className="security-section">
        <h3>Dispositivos Conectados</h3>
        <ul className="device-list">
          {securitySettings.connectedDevices.map((device) => (
            <li key={device}>
              {device}
              <button
                className="remove-device"
                onClick={() => handleRemoveDevice(device)}
              >
                Remover
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* Pergunta de segurança */}
      <div className="security-section">
        <h3>Pergunta de Segurança</h3>
        <input
          type="text"
          value={securitySettings.securityQuestion}
          placeholder="Digite sua pergunta de segurança"
          onChange={(e) =>
            setSecuritySettings((prev) => ({
              ...prev,
              securityQuestion: e.target.value,
            }))
          }
        />
      </div>

      {/* PIN */}
      <div className="security-section">
        <h3>PIN</h3>
        <input
          type="password"
          value={securitySettings.pin}
          placeholder="Defina um PIN"
          onChange={(e) =>
            setSecuritySettings((prev) => ({
              ...prev,
              pin: e.target.value,
            }))
          }
        />
      </div>

      {/* Histórico de atividades */}
      <div className="security-section">
        <h3>Histórico de Atividades</h3>
        <ul className="activity-list">
          {securitySettings.activityHistory.map((activity) => (
            <li key={activity.id}>
              {activity.action} - {activity.date}
            </li>
          ))}
        </ul>
      </div>

      {/* Botão Salvar */}
      <button className="save-button" onClick={handleSaveSettings}>
        Salvar Configurações
      </button>
    </div>
  );
};

export default SecuritySettings;
