import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,

  withCredentials: true, // Permite o envio de cookies
  headers: {
    "Content-Type": "application/json",
  },
});
console.log("Dentro da api BaseURL usada:", api.defaults.baseURL);

export default api;
