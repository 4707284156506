import React, { useState } from "react";

const NotificationPreferences = () => {
  const [preferences, setPreferences] = useState({
    promo: true,
    reminder: true,
    update: true,
    method: "push",
  });

  const handleToggle = (key) => {
    setPreferences({ ...preferences, [key]: !preferences[key] });
  };

  const handleMethodChange = (method) => {
    setPreferences({ ...preferences, method });
  };

  const handleSave = () => {
    console.log("Preferências salvas:", preferences);
  };

  return (
    <div className="notification-preferences-container">
      <h2>Preferências de Notificação</h2>
      <div className="preference-item">
        <label>
          <input
            type="checkbox"
            checked={preferences.promo}
            onChange={() => handleToggle("promo")}
          />
          Promoções
        </label>
      </div>
      <div className="preference-item">
        <label>
          <input
            type="checkbox"
            checked={preferences.reminder}
            onChange={() => handleToggle("reminder")}
          />
          Lembretes de Agendamento
        </label>
      </div>
      <div className="preference-item">
        <label>
          <input
            type="checkbox"
            checked={preferences.update}
            onChange={() => handleToggle("update")}
          />
          Atualizações
        </label>
      </div>
      <div className="notification-method">
        <label>Método de Notificação:</label>
        <select
          value={preferences.method}
          onChange={(e) => handleMethodChange(e.target.value)}
        >
          <option value="push" className="margin-div">
            Push
          </option>
          <option value="email" className="margin-div">
            Email
          </option>
          <option value="sms" className="margin-div">
            SMS
          </option>
        </select>
      </div>
      <button className="save-button" onClick={handleSave}>
        Salvar
      </button>
    </div>
  );
};

export default NotificationPreferences;
