import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./PhoneField.css";
import { Box } from "@mui/material";

const PhoneField = ({ value, onChange }) => {
  return (
    <div className="phone-field">
      Telefone
      <Box sx={{ display: "flex", alignItems: "center", marginLeft: "50px" }}>
        {/* Campo de Telefone */}
        <PhoneInput
          country="br"
          value={value} // Recebe o valor do número completo
          onChange={onChange} // Envia alterações ao componente pai
          inputStyle={{
            height: "55px",
            width: "100%",
            paddingLeft: "55px",
            borderRadius: "8px",
          }}
          buttonStyle={{ borderRadius: "8px 0 0 8px" }}
          containerStyle={{ marginBottom: "16px" }}
        />
      </Box>
    </div>
  );
};

export default PhoneField;
