import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import { useAuth } from "../../contexts/AuthContext";
import { login as loginService, getProfile } from "../../services/authService";
import "./Login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { login } = useAuth(); // Função do contexto para atualizar o user globalmente
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      // Passo 1: Fazer login no backend (não retorna user)
      await loginService(email, password);

      // Passo 2: Obter os dados do usuário logado através do /auth/me
      const userData = await getProfile();

      // Passo 3: Atualizar o contexto global com os dados do usuário
      login(userData);

      // Redirecionar para o dashboard
      navigate("/dashboard");
    } catch (err) {
      setError(err.message || "Erro ao fazer login");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <h2>Faça seu login</h2>
      {error && <p className="error-message">{error}</p>}
      <form className="login-form" onSubmit={handleLogin}>
        <label>Email</label>
        <input
          type="email"
          placeholder="Digite seu email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={loading}
        />
        <label>Senha</label>
        <input
          type="password"
          placeholder="Digite sua senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={loading}
        />
        <Button
          text={loading ? "Entrando..." : "Entrar"}
          type="submit"
          variant="primary"
          disabled={loading}
        />
      </form>
    </div>
  );
};

export default Login;
