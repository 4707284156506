import React, { useState } from "react";
import "./PrivacySettings.css";

const PrivacySettings = () => {
  const [privacySettings, setPrivacySettings] = useState({
    showProfile: "everyone", // Quem pode ver o perfil
    showStatus: "friends", // Quem pode ver o status online
  });

  const handlePrivacyChange = (setting, value) => {
    setPrivacySettings((prev) => ({ ...prev, [setting]: value }));
  };

  const handleSaveSettings = () => {
    console.log("Configurações de privacidade salvas:", privacySettings);
    // Aqui pode ser feita a chamada à API para salvar as configurações
  };

  return (
    <div className="privacy-settings-container">
      <h2>Configurações de Privacidade</h2>
      <div className="privacy-setting">
        <label htmlFor="showProfile">Quem pode ver seu perfil:</label>
        <select
          id="showProfile"
          value={privacySettings.showProfile}
          onChange={(e) => handlePrivacyChange("showProfile", e.target.value)}
        >
          <option value="everyone">Todos</option>
          <option value="friends">Apenas Amigos</option>
          <option value="noone">Ninguém</option>
        </select>
      </div>
      <div className="privacy-setting">
        <label htmlFor="showStatus">Quem pode ver seu status online:</label>
        <select
          id="showStatus"
          value={privacySettings.showStatus}
          onChange={(e) => handlePrivacyChange("showStatus", e.target.value)}
        >
          <option value="everyone">Todos</option>
          <option value="friends">Apenas Amigos</option>
          <option value="noone">Ninguém</option>
        </select>
      </div>
      <button className="save-button" onClick={handleSaveSettings}>
        Salvar Configurações
      </button>
    </div>
  );
};

export default PrivacySettings;
