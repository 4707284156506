import React, { useState } from "react";
import "./Historico.css";

const Historico = () => {
  const [activeTab, setActiveTab] = useState("transacoes"); // Tab ativa
  const [expandedItemId, setExpandedItemId] = useState(null); // Item expandido para detalhes

  const transacoes = [
    {
      id: 1,
      type: "Pagamento",
      date: "2023-12-05",
      amount: "49,90",
      status: "Concluído",
      details: "Plano Premium - Cobrança Mensal",
    },
    {
      id: 2,
      type: "Reembolso",
      date: "2023-11-20",
      amount: "-19,90",
      status: "Concluído",
      details: "Reembolso do Plano Básico",
    },
  ];

  const agendamentos = [
    {
      id: 1,
      service: "Corte de Cabelo",
      date: "2023-12-07",
      time: "15:30",
      status: "Realizado",
      details: "Serviço concluído com sucesso.",
    },
    {
      id: 2,
      service: "Massagem Relaxante",
      date: "2023-11-30",
      time: "10:00",
      status: "Cancelado",
      details: "Cancelado pelo cliente.",
    },
  ];

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setExpandedItemId(null); // Fecha detalhes ao trocar de aba
  };

  const toggleDetails = (id) => {
    setExpandedItemId((prevId) => (prevId === id ? null : id));
  };

  const renderDetails = (item) => (
    <div className="details">
      {activeTab === "transacoes" ? (
        <>
          <p>
            <strong>Tipo:</strong> {item.type}
          </p>
          <p>
            <strong>Data:</strong> {item.date}
          </p>
          <p>
            <strong>Valor:</strong> R$ {item.amount}
          </p>
          <p>
            <strong>Status:</strong> {item.status}
          </p>
          <p>
            <strong>Detalhes:</strong> {item.details}
          </p>
        </>
      ) : (
        <>
          <p>
            <strong>Serviço:</strong> {item.service}
          </p>
          <p>
            <strong>Data:</strong> {item.date}
          </p>
          <p>
            <strong>Horário:</strong> {item.time}
          </p>
          <p>
            <strong>Status:</strong> {item.status}
          </p>
          <p>
            <strong>Detalhes:</strong> {item.details}
          </p>
        </>
      )}
    </div>
  );

  const data = activeTab === "transacoes" ? transacoes : agendamentos;

  return (
    <div className="historico-container">
      <h2>Histórico</h2>

      <div className="tabs-container">
        <button
          className={`tab-button ${activeTab === "transacoes" ? "active" : ""}`}
          onClick={() => handleTabChange("transacoes")}
        >
          Transações
        </button>
        <button
          className={`tab-button ${
            activeTab === "agendamentos" ? "active" : ""
          }`}
          onClick={() => handleTabChange("agendamentos")}
        >
          Agendamentos
        </button>
      </div>

      <ul className="historico-list">
        {data.map((item) => (
          <li key={item.id} className="historico-item">
            <div className="item-summary">
              <h4>{activeTab === "transacoes" ? item.type : item.service}</h4>
              <p>{item.date}</p>
              <button
                className="details-button"
                onClick={() => toggleDetails(item.id)}
              >
                {expandedItemId === item.id ? "Ocultar" : "Detalhes"}
              </button>
            </div>
            {expandedItemId === item.id && renderDetails(item)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Historico;
