import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import Cadastro from "../pages/Cadastro/Cadastro";
import Dashboard from "../pages/Dashboard/Dashboard";
import UserProfile from "../pages/UserProfile/UserProfile";
import Agendamentos from "../pages/Agendamentos/Agendamentos";
import Notifications from "../pages/Notifications/Notifications";
import UserSettings from "../pages/UserSettings/UserSettings";
import MeuPlano from "../pages/MeuPlano/MeuPlano";
import Historico from "../pages/Historico/Historico";
import BusinessHome from "../pages/BusinessHome/BusinessHome";
import BusinessRegister from "../pages/BusinessRegister/BusinessRegister";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/cadastro" element={<Cadastro />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/perfil" element={<UserProfile />} />
      <Route path="/agendamentos" element={<Agendamentos />} />
      <Route path="/notificacoes" element={<Notifications />} />
      <Route path="/settings" element={<UserSettings />} />
      <Route path="/meuplano" element={<MeuPlano />} />
      <Route path="/historico" element={<Historico />} />
      <Route path="/business/:businessId" element={<BusinessHome />} />
      <Route path="/business/create" element={<BusinessRegister />} />
    </Routes>
  );
};

export default AppRoutes;
