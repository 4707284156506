import React from "react";
import "./Logo.css";
import logoImage from "../../assets/images/logo.png"; // Importação da imagem correta

const Logo = () => {
  return (
    <div className="logo-container">
      <img src={logoImage} alt="Logo Linka" className="logo" />
      <h1 className="logo-text">Linka</h1>
    </div>
  );
};

export default Logo;
