import React, { useRef } from "react";
import api from "../../services/api"; // Certifique-se de usar o caminho correto
import imageCompression from "browser-image-compression";

const LogoUpload = ({ businessId, logoUrl, onChange }) => {
  const fileInputRef = useRef();

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      console.log("Iniciando upload do logo...");
      // Opções de compressão
      const options = {
        maxSizeMB: 1, // Tamanho máximo em MB
        maxWidthOrHeight: 800, // Largura/altura máxima
        useWebWorker: true,
      };

      // Compressão da imagem
      const compressedFile = await imageCompression(file, options);

      // Criação do FormData
      const formData = new FormData();
      formData.append("file", compressedFile);

      console.log("FormData criado:", formData);
      console.log("ID do negócio:", businessId);
      // Upload para a API
      const response = await api.post(
        `/business/${businessId}/upload-logo`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Atualizar a URL do logo
      const data = response.data;
      onChange(data.logoUrl); // Atualize a URL do logo no estado do componente pai
      console.log("Upload concluído:", data.logoUrl);
    } catch (error) {
      console.error("Erro ao fazer upload ou compactar imagem:", error);
    }
  };

  return (
    <div style={styles.container} onClick={handleClick}>
      <img src={logoUrl} alt="Nova Logo" style={styles.preview} />
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        accept="image/*"
        onChange={handleUpload} // Lida com o upload e a compressão
      />
      <span style={styles.text}>Clique para alterar logotipo</span>
    </div>
  );
};

const styles = {
  container: {
    border: "2px dashed #ccc",
    borderRadius: "8px",
    padding: "1rem",
    textAlign: "center",
    cursor: "pointer",
    width: "150px",
    height: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  preview: {
    width: "100px",
    height: "100px",
    objectFit: "cover",
    borderRadius: "50%",
    marginBottom: "0.5rem",
  },
  text: {
    fontSize: "0.9rem",
    color: "#666",
  },
};

export default LogoUpload;
