import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import "./assets/styles/global.css";
import "./App.css";

const App = () => {
  return (
    <Router>
      <div className="app">
        {/* Header */}
        <Header />

        {/* Main Content */}
        <main className="main-content">
          <AppRoutes />
        </main>

        {/* Footer */}
        <Footer />
      </div>
    </Router>
  );
};

export default App;
