import React from "react";
import "./Card.css"; // Arquivo de estilização separado (opcional)

const Card = ({ icon, title, content, onClick }) => {
  return (
    <div className="card-container" onClick={onClick}>
      <div className="card-icon">{icon}</div>
      <div className="card-title">{title}</div>
      <div className="card-content">{content}</div>
    </div>
  );
};

export default Card;
