import React, { useState, useEffect } from "react";
import "./Agendamentos.css";

const Agendamentos = () => {
  // Estados para controle de abas e agendamentos
  const [activeTab, setActiveTab] = useState("futuros");
  const [agendamentosFuturos, setAgendamentosFuturos] = useState([]);
  const [agendamentosPassados, setAgendamentosPassados] = useState([]);
  const [selectedAgendamento, setSelectedAgendamento] = useState(null);

  // Mock para carregamento inicial dos agendamentos

  useEffect(() => {
    if (activeTab === "futuros") {
      setAgendamentosFuturos([
        {
          id: 1,
          titulo: "Corte de Cabelo",
          data: "12/12/2025",
          horario: "15:30",
          status: "Confirmado",
          descricao: "Corte moderno com máquina e tesoura.",
        },
        {
          id: 2,
          titulo: "Manicure",
          data: "14/12/2025",
          horario: "10:00",
          status: "Pendente",
          descricao: "Aplicação de esmalte e cuidados com unhas.",
        },
      ]);
    } else {
      setAgendamentosPassados([
        {
          id: 3,
          titulo: "Massagem Relaxante",
          data: "2023-12-01",
          status: "Cancelado",
        },
        { id: 4, titulo: "Barba", data: "2023-11-30", status: "Confirmado" },
      ]);
    }
  }, [activeTab]);

  // Alterna entre as abas (futuros ou passados)
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Seleciona um agendamento para exibir no card de detalhes
  const handleSelectAgendamento = (agendamento) => {
    setSelectedAgendamento(agendamento);
  };

  return (
    <div className="agendamentos-container-dad">
      <div className="agendamentos-container">
        {/* Botões de abas */}
        <div className="tabs-container">
          <button
            className={`tab-button ${activeTab === "passados" ? "active" : ""}`}
            onClick={() => handleTabChange("passados")}
          >
            Agendamentos Passados
          </button>
          <button
            className={`tab-button ${activeTab === "futuros" ? "active" : ""}`}
            onClick={() => handleTabChange("futuros")}
          >
            Agendamentos Futuros
          </button>
        </div>

        <div className="lista-agendamentos">
          {activeTab === "futuros" &&
            agendamentosFuturos.map((item) => (
              <div
                key={item.id}
                className="agendamento-item"
                onClick={() => handleSelectAgendamento(item)}
              >
                <div className="item-content">
                  <div className="item-icon">
                    {item.status === "Confirmado" && (
                      <span className="status-icon">✔️</span>
                    )}
                    {item.status === "Pendente" && (
                      <span className="status-icon">⏳</span>
                    )}
                    {item.status === "Cancelado" && (
                      <span className="status-icon">❌</span>
                    )}
                  </div>
                  <div className="item-details">
                    <h4>{item.titulo}</h4>
                    <p>
                      {item.data} - {item.horario}
                    </p>
                  </div>
                </div>
              </div>
            ))}

          {activeTab === "passados" &&
            agendamentosPassados.slice(0, 5).map((item) => (
              <div
                key={item.id}
                className="agendamento-item"
                onClick={() => handleSelectAgendamento(item)}
              >
                <div className="item-content">
                  <div className="item-icon">
                    {item.status === "Confirmado" && (
                      <span className="status-icon">✔️</span>
                    )}
                    {item.status === "Pendente" && (
                      <span className="status-icon">⏳</span>
                    )}
                    {item.status === "Cancelado" && (
                      <span className="status-icon">❌</span>
                    )}
                  </div>
                  <div className="item-details">
                    <h4>{item.titulo}</h4>
                    <p>
                      {item.data} - {item.horario}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* Card de Detalhes */}
      {selectedAgendamento && (
        <div className="detalhes-agendamento">
          <h2>{selectedAgendamento.titulo}</h2>
          <p>Data: {selectedAgendamento.data}</p>
          <p>Horário: {selectedAgendamento.horario}</p>
          <p>Status: {selectedAgendamento.status}</p>
          <p>
            Descrição:{" "}
            {selectedAgendamento.descricao || "Sem descrição disponível."}
          </p>

          {/* Verificar se o agendamento é futuro */}
          {activeTab === "futuros" && (
            <div className="botoes-detalhes">
              <button
                className="botao-editar"
                onClick={() => console.log("Editar", selectedAgendamento)}
              >
                Editar
              </button>
              <button
                className="botao-cancelar"
                onClick={() => console.log("Cancelar", selectedAgendamento)}
              >
                Cancelar
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Agendamentos;
