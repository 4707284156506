import React from "react";
import "./Button.css";

const Button = ({
  text,
  onClick,
  type = "button",
  variant = "primary", // Define o estilo do botão
  size = "medium", // Define o tamanho do botão
  isDisabled = false, // Adiciona funcionalidade de desabilitado
  icon = null, // Ícone opcional
}) => {
  return (
    <button
      className={`btn btn-${variant} btn-${size}`}
      type={type}
      onClick={onClick}
      disabled={isDisabled}
    >
      {icon && <span className="btn-icon">{icon}</span>}
      {text}
    </button>
  );
};

export default Button;
