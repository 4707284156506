import React, { useState } from "react";
import {
  FaCamera,
  FaBuilding,
  FaFileAlt,
  FaKey,
  FaCreditCard,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom"; // Importar useNavigate
import { createBusiness } from "../../services/businessService";
import "./BusinessRegister.css";

const BusinessRegister = () => {
  const [formData, setFormData] = useState({
    logo: null,
    name: "",
    description: "",
    cnpj: "",
    bankAccount: "",
  });

  const navigate = useNavigate(); // Instanciar useNavigate
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      logo: e.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const businessData = {
        name: formData.name,
        schedule: "9:00 AM - 6:00 PM", // Mock de horário
      };

      const result = await createBusiness(businessData);
      alert("Negócio criado com sucesso!");
      console.log(result);
      // Redirecionar para a rota "/"
      navigate("/");
    } catch (error) {
      alert(error.message || "Erro ao criar negócio");
    }
  };

  return (
    <div className="business-register-container">
      <h2>Cadastre seu Negócio</h2>
      <form className="business-register-form" onSubmit={handleSubmit}>
        {/* Logotipo */}
        <div className="form-group">
          <label htmlFor="logo">
            <FaCamera className="form-icon" /> Logotipo
          </label>
          <input
            type="file"
            id="logo"
            name="logo"
            accept="image/*"
            onChange={handleFileChange}
          />
        </div>

        {/* Nome do negócio */}
        <div className="form-group">
          <label htmlFor="name">
            <FaBuilding className="form-icon" /> Nome do Negócio
          </label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Digite o nome do negócio"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </div>

        {/* Descrição */}
        <div className="form-group">
          <label htmlFor="description">
            <FaFileAlt className="form-icon" /> Descrição
          </label>
          <textarea
            id="description"
            name="description"
            placeholder="Descreva seu negócio"
            value={formData.description}
            onChange={handleInputChange}
            rows="4"
          ></textarea>
        </div>

        {/* CNPJ */}
        <div className="form-group">
          <label htmlFor="cnpj">
            <FaKey className="form-icon" /> CNPJ
          </label>
          <input
            type="text"
            id="cnpj"
            name="cnpj"
            placeholder="Digite o CNPJ"
            value={formData.cnpj}
            onChange={handleInputChange}
          />
        </div>

        {/* Conta bancária */}
        <div className="form-group">
          <label htmlFor="bankAccount">
            <FaCreditCard className="form-icon" /> Conta Bancária
          </label>
          <input
            type="text"
            id="bankAccount"
            name="bankAccount"
            placeholder="Digite a conta bancária"
            value={formData.bankAccount}
            onChange={handleInputChange}
          />
        </div>

        <button type="submit" className="submit-button">
          Cadastrar Negócio
        </button>
      </form>
    </div>
  );
};

export default BusinessRegister;
