import api from "./api";

export const createBusiness = async (businessData) => {
  try {
    const response = await api.post("/business/create", businessData);
    return response.data; // Retorna os dados do negócio criado
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Erro ao criar o negócio";
    console.error("Create Business Error:", errorMessage);
    throw new Error(errorMessage);
  }
};

export const getAllBusinesses = async () => {
  try {
    const response = await api.get("/business/all");
    return response.data; // Retorna todos os negócios cadastrados
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Erro ao buscar negócios";
    console.error("Get All Businesses Error:", errorMessage);
    throw new Error(errorMessage);
  }
};

export const getBusinessById = async (businessId) => {
  try {
    const response = await api.get(`/business/${businessId}`);
    return response.data;
  } catch (error) {
    console.error("Erro ao buscar o negócio:", error);
    throw new Error("Erro ao buscar os dados do negócio.");
  }
};

// Adicionando a função de atualizar o negócio
export const updateBusiness = async (businessId, updatedData) => {
  try {
    const response = await api.put(`/business/${businessId}`, updatedData);
    return response.data; // Retorna os dados atualizados do negócio
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Erro ao atualizar o negócio";
    console.error("Update Business Error:", errorMessage);
    throw new Error(errorMessage);
  }
};
