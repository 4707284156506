import React, { useState } from "react";
import "./PasswordValidationForm.css";

const PasswordValidationForm = ({ onPasswordChange }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    // Se o usuário não digitar nada, não mostramos erro nem validamos
    if (!newPassword.trim()) {
      setPasswordError("");
      onPasswordChange(""); // Informa ao pai que não há senha
      return;
    }

    // Validações de comprimento
    if (newPassword.length < 4) {
      setPasswordError("A senha precisa ter pelo menos 4 caracteres.");
      onPasswordChange(""); // Não passa senha inválida ao pai
      return;
    } else if (newPassword.length > 15) {
      setPasswordError("A senha não pode ter mais de 15 caracteres.");
      onPasswordChange("");
      return;
    } else {
      // Se a senha é válida em comprimento, limpamos o erro dessa parte
      setPasswordError("");
    }

    // Se o confirmPassword estiver preenchido, verifica se coincide
    if (confirmPassword && newPassword !== confirmPassword) {
      setPasswordError("As senhas não coincidem!");
      onPasswordChange("");
    } else {
      // Tudo certo, nenhuma mensagem de erro
      setPasswordError("");
      onPasswordChange(newPassword);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);

    // Só checa coincidência se a senha estiver preenchida
    if (password && newConfirmPassword !== password) {
      setPasswordError("As senhas não coincidem!");
      onPasswordChange("");
    } else {
      if (password.length >= 4 && password.length <= 15) {
        // Se a senha é válida em tamanho e as senhas coincidem ou o confirmPassword está vazio
        setPasswordError("");
        onPasswordChange(password);
      } else if (!password.trim()) {
        // Se a senha estiver vazia, não há erro
        setPasswordError("");
        onPasswordChange("");
      }
    }
  };

  return (
    <div className="password-field">
      <input
        type="password"
        name="password"
        value={password}
        onChange={handlePasswordChange}
        placeholder="Nova Senha (opcional)"
      />
      <input
        type="password"
        name="confirmPassword"
        value={confirmPassword}
        onChange={handleConfirmPasswordChange}
        placeholder="Confirme a Senha (se trocou)"
      />
      {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}
    </div>
  );
};

export default PasswordValidationForm;
