import React, { useState, useEffect } from "react";
import axios from "axios";
import "./UserProfile.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { updateProfile } from "../../services/authService";
import Button from "../../components/Button/Button";
import PhoneField from "../../components/PhoneField/PhoneField";
import ProfilePictureUpload from "../../components/ProfilePictureUpload/ProfilePictureUpload";
import PasswordValidationForm from "../../components/PasswordValidationForm/PasswordValidationForm";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import { FaSave } from "react-icons/fa";

const phoneUtil = PhoneNumberUtil.getInstance();

const UserProfile = () => {
  const navigate = useNavigate();
  const { user, login, logout } = useAuth();
  const [editing, setEditing] = useState(false);

  const [formData, setFormData] = useState({
    name: user?.name || "",
    email: user?.email || "",
    nickname: user?.nickname || "",
    tel: user?.tel || "",
    password: "",
    confirmPassword: "",
  });

  // Função para formatar o telefone no formato brasileiro
  const formatPhoneNumber = (phone) => {
    try {
      const parsedNumber = phoneUtil.parse(phone, "BR"); // Assume que o número é do Brasil
      return phoneUtil.format(parsedNumber, PhoneNumberFormat.NATIONAL); // Exemplo: (19) 98254-6385
    } catch (error) {
      console.error("Erro ao formatar número de telefone:", error);
      return phone; // Retorna o número original em caso de erro
    }
  };

  const handlePasswordChange = (newPassword) => {
    setFormData({ ...formData, password: newPassword });
  };

  // Atualiza a foto de perfil no contexto após o upload
  const updatePicture = (newProfilePicture) => {
    login({ ...user, profilePicture: newProfilePicture });
  };

  useEffect(() => {
    if (!user) {
      navigate("/"); // Redireciona para a página de login
    }
  }, [user, navigate]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Validação para telefone
    if (name === "tel" && value.length > 15) {
      alert("O telefone deve ter no máximo 15 caracteres.");
      return;
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Atualiza o número de telefone no formData
  const handlePhoneChange = (newPhoneNumber) => {
    setFormData({ ...formData, tel: newPhoneNumber });
  };

  // Update user data
  const handleUpdate = async () => {
    try {
      // Cria uma cópia do formData
      const updatedData = { ...formData };

      // Se a senha estiver vazia, removemos do objeto antes de enviar ao backend
      if (!updatedData.password.trim()) {
        delete updatedData.password;
      }

      console.log("User ID:", user.id);
      // Agora chamamos o updateProfile passando updatedData já "limpo"
      const updatedUser = await updateProfile(user.id, updatedData);

      login({ ...user, ...updatedUser }); // Atualiza o contexto com os novos dados
      setEditing(false);
      alert("Profile updated successfully!");
    } catch (err) {
      alert(err.message || "Failed to update profile.");
    }
  };

  // Delete user account
  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete your account?")) {
      try {
        await axios.delete(`/users/delete/${user.id}`);
        logout(); // Remove o usuário do contexto
        alert("Account deleted. Logging out...");
        // Redirecionar para a página inicial ou de login
      } catch (err) {
        alert("Failed to delete account.");
      }
    }
  };

  return (
    <div className="user-profile">
      <h1>Perfil</h1>
      <div className="profile-info">
        {/* Substitui a exibição estática da imagem pelo componente de upload */}
        {user && (
          <ProfilePictureUpload user={user} updatePicture={updatePicture} />
        )}

        {/* <ProfilePictureUpload user={user} updatePicture={updatePicture} /> */}
        {!editing ? (
          <>
            <p>Nome: {user?.name}</p>
            <p>Email: {user?.email}</p>
            <p>Apelido: {user?.nickname}</p>
            <p>
              Telefone:{" "}
              {user?.tel ? formatPhoneNumber(user.tel) : "Não cadastrado"}
            </p>
            <button onClick={() => setEditing(true)}>Editar Perfil</button>
            <button onClick={handleDelete} className="delete-btn">
              Deletar Conta
            </button>
          </>
        ) : (
          <div className="edit-form">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Name"
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email"
            />

            <input
              type="text"
              name="nickname"
              value={formData.nickname}
              onChange={handleInputChange}
              placeholder="Apelido"
            />

            {/* Senha e Confirmar senha */}
            <PasswordValidationForm
              name="tel"
              onPasswordChange={handlePasswordChange}
            />

            {/* Aqui substituímos o input de telefone pelo PhoneField */}

            <PhoneField
              value={formData.tel}
              onChange={handlePhoneChange} // Atualiza o número no formData
            />

            <div className="btn-group">
              {/* Botão para salvar */}
              <Button
                text="Salvar"
                variant="primary"
                size="medium"
                icon={<FaSave />}
                onClick={handleUpdate}
              />

              {/* Botão para cancelar */}
              <Button
                text="Cancelar"
                variant="danger"
                size="small"
                onClick={() => setEditing(false)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
