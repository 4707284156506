import React, { useState } from "react";
import "./AccountSettings.css";

const AccountSettings = () => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteAccount = () => {
    if (window.confirm("Tem certeza de que deseja excluir sua conta?")) {
      setIsDeleting(true);
      // Simula uma chamada de API para exclusão da conta
      setTimeout(() => {
        alert("Conta excluída com sucesso.");
        setIsDeleting(false);
        // Redirecionar ou realizar logout
      }, 2000);
    }
  };

  const handleUpdateAccount = (e) => {
    e.preventDefault();
    alert("Configurações atualizadas com sucesso!");
    // Aqui pode haver uma chamada de API para salvar alterações
  };

  return (
    <div className="account-settings-container">
      <h2>Configurações de Conta</h2>

      {/* Configurações Gerais */}
      <form onSubmit={handleUpdateAccount} className="account-form">
        <div className="form-group">
          <label htmlFor="name">Nome</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Seu nome"
            defaultValue="Fulano de Tal"
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Seu email"
            defaultValue="email@example.com"
          />
        </div>

        <div className="form-group">
          <label htmlFor="password">Nova Senha</label>
          <input
            type="password"
            id="password"
            name="password"
            placeholder="Deixe vazio para manter a senha atual"
          />
        </div>

        <button type="submit" className="save-button">
          Salvar Alterações
        </button>
      </form>

      {/* Exclusão de Conta */}
      <div className="delete-account-section">
        <h3>Excluir Conta</h3>
        <p>
          Ao excluir sua conta, todos os seus dados serão permanentemente
          apagados. Essa ação não pode ser desfeita.
        </p>
        <button
          className={`delete-button ${isDeleting ? "loading" : ""}`}
          onClick={handleDeleteAccount}
          disabled={isDeleting}
        >
          {isDeleting ? "Excluindo..." : "Excluir Conta"}
        </button>
      </div>
    </div>
  );
};

export default AccountSettings;
