import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BusinessHeader from "../../components/BusinessHeader/BusinessHeader";
import LogoUpload from "../../components/LogoUpload/LogoUpload"; // Importar o componente
import {
  getBusinessById,
  updateBusiness,
} from "../../services/businessService";

const BusinessHome = () => {
  const { businessId } = useParams(); // Captura o businessId da URL
  const [business, setBusiness] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBusiness = async () => {
      try {
        const businessData = await getBusinessById(businessId);
        setBusiness(businessData);
      } catch (err) {
        setError("Não foi possível carregar os dados do negócio.");
      } finally {
        setLoading(false);
      }
    };

    fetchBusiness();
  }, [businessId]);

  const handleUpdateBusinessInfo = async (newName, newLogoUrl) => {
    if (!business) return;

    const updatedData = {
      name: newName || business.name,
      logoUrl: newLogoUrl || business.logoUrl, // Atualizar o logo
    };

    try {
      const updatedBusiness = await updateBusiness(businessId, updatedData);
      setBusiness(updatedBusiness);
    } catch (err) {
      console.error("Erro ao atualizar o negócio:", err);
    }
  };

  if (loading) {
    return <p>Carregando...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!business) {
    return <p>Negócio não encontrado!</p>;
  }

  return (
    <div>
      <BusinessHeader
        name={business.name}
        logoUrl={business.logoUrl}
        onUpdate={handleUpdateBusinessInfo}
      />

      {/* Adicione o LogoUpload */}
      <LogoUpload
        businessId={businessId} // Passa o ID do negócio
        logoUrl={business.logoUrl} // URL atual do logo
        onChange={(newLogoUrl) => handleUpdateBusinessInfo(null, newLogoUrl)} // Atualiza apenas o logo
      />

      <p>Horário de Funcionamento: {business.schedule}</p>
    </div>
  );
};

export default BusinessHome;
