import React from "react";
import "./Home.css";
import { useAuth } from "../../contexts/AuthContext";
import BusinessMenu from "../../components/BusinessMenu/BusinessMenu";

const Home = () => {
  const { user } = useAuth();

  return (
    <div className="home-container">
      {/* Header */}
      {/* <header className="home-header">
        <div className="header-left">
          <img src="/logo.png" alt="Logo Linka" className="home-logo" />
        </div>
        <div className="header-center">
          <input
            type="text"
            placeholder="O que você está procurando?"
            className="search-bar"
          />
        </div>
        <div className="header-right">
          <BusinessMenu />
        </div>
      </header> */}

      {/* Conteúdo Principal */}
      <main>
        <section className="welcome-section">
          <h1>Bem-vindo{user && `, ${user.name}`}!</h1>
          <p>
            Explore os melhores serviços e promoções personalizados para você.
          </p>
        </section>

        {user ? (
          <>
            <section className="promotions-section">
              <h2>Promoções em Destaque</h2>
              <div className="promotions">
                <div className="promotion-card">
                  <h3>Massagem Relaxante</h3>
                  <p>Desconto de 20% - válido até 20/12</p>
                </div>
                <div className="promotion-card">
                  <h3>Corte de Cabelo</h3>
                  <p>R$ 49,90 - promoção especial</p>
                </div>
              </div>
            </section>

            <section className="recommendations-section">
              <h2>Recomendações Personalizadas</h2>
              <div className="recommendations">
                <div className="recommendation-card">
                  <h3>Manicure Completa</h3>
                  <p>Baseado nas suas preferências</p>
                </div>
                <div className="recommendation-card">
                  <h3>Treino Personalizado</h3>
                  <p>Popular entre os usuários</p>
                </div>
              </div>
            </section>
          </>
        ) : (
          <></>
        )}
      </main>
    </div>
  );
};

export default Home;
