import React, { useState } from "react";
import "./NotificationPreferencesModal.css";

const NotificationPreferencesModal = ({ isOpen, onClose, onSave }) => {
  const [preferences, setPreferences] = useState({
    promo: true,
    reminder: true,
    update: true,
    method: "push", // Opções: push, email, sms
  });

  const handleCheckboxChange = (type) => {
    setPreferences((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const handleMethodChange = (method) => {
    setPreferences((prev) => ({
      ...prev,
      method,
    }));
  };

  const handleSave = () => {
    onSave(preferences); // Envia as preferências para o componente pai
    onClose(); // Fecha o modal
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Preferências de Notificações</h2>
        <div className="preferences-section">
          <h3>Tipos de Notificação</h3>
          <label>
            <input
              type="checkbox"
              checked={preferences.promo}
              onChange={() => handleCheckboxChange("promo")}
            />
            Promoções
          </label>
          <label>
            <input
              type="checkbox"
              checked={preferences.reminder}
              onChange={() => handleCheckboxChange("reminder")}
            />
            Lembretes
          </label>
          <label>
            <input
              type="checkbox"
              checked={preferences.update}
              onChange={() => handleCheckboxChange("update")}
            />
            Atualizações
          </label>
        </div>

        <div className="preferences-section">
          <h3>Método de Recebimento</h3>
          <label>
            <input
              type="radio"
              name="method"
              value="push"
              checked={preferences.method === "push"}
              onChange={() => handleMethodChange("push")}
            />
            Push
          </label>
          <label>
            <input
              type="radio"
              name="method"
              value="email"
              checked={preferences.method === "email"}
              onChange={() => handleMethodChange("email")}
            />
            Email
          </label>
          <label>
            <input
              type="radio"
              name="method"
              value="sms"
              checked={preferences.method === "sms"}
              onChange={() => handleMethodChange("sms")}
            />
            SMS
          </label>
        </div>

        <div className="modal-actions">
          <button className="save-button" onClick={handleSave}>
            Salvar
          </button>
          <button className="cancel-button" onClick={onClose}>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotificationPreferencesModal;
