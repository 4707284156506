import React, { useState } from "react";
import { IoPencil } from "react-icons/io5";
import LogoUpload from "../LogoUpload/LogoUpload";

const BusinessHeader = ({ name, logoUrl, onUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempName, setTempName] = useState(name);
  const [tempLogo, setTempLogo] = useState(logoUrl);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setTempName(name);
    setTempLogo(logoUrl);
    setIsEditing(false);
  };

  const handleSave = () => {
    onUpdate(tempName, tempLogo);
    setIsEditing(false);
  };

  return (
    <div style={styles.container}>
      <div style={styles.logoSection}>
        {isEditing ? (
          <LogoUpload
            logoUrl={tempLogo}
            onChange={(newLogoUrl) => setTempLogo(newLogoUrl)}
          />
        ) : (
          <img src={logoUrl} alt="Logotipo do negócio" style={styles.logo} />
        )}
      </div>
      <div style={styles.infoSection}>
        {isEditing ? (
          <input
            type="text"
            value={tempName}
            onChange={(e) => setTempName(e.target.value)}
            style={styles.nameInput}
          />
        ) : (
          <h1 style={styles.nameText}>{name}</h1>
        )}
        {!isEditing && (
          <button
            onClick={handleEditClick}
            style={styles.editButton}
            title="Editar Informações"
          >
            <IoPencil />
          </button>
        )}
      </div>
      {isEditing && (
        <div style={styles.actions}>
          <button onClick={handleSave} style={styles.saveBtn}>
            Salvar
          </button>
          <button onClick={handleCancel} style={styles.cancelBtn}>
            Cancelar
          </button>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    background: "#fff",
    borderRadius: "8px",
    padding: "1.5rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
    maxWidth: "400px",
    margin: "0 auto",
  },
  logoSection: {
    marginBottom: "1rem",
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    width: "150px",
    height: "150px",
    objectFit: "cover",
    borderRadius: "50%",
  },
  infoSection: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  nameText: {
    margin: "0",
    fontSize: "1.5rem",
    color: "#333",
    textAlign: "center",
  },
  nameInput: {
    fontSize: "1.2rem",
    padding: "0.5rem",
    borderRadius: "4px",
    border: "1px solid #ccc",
    textAlign: "center",
  },
  editButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
    marginLeft: "0.5rem",
    fontSize: "1.2rem",
    color: "#333",
  },
  actions: {
    marginTop: "1rem",
    display: "flex",
    gap: "0.5rem",
  },
  saveBtn: {
    background: "#6b63ff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    padding: "0.5rem 1rem",
    cursor: "pointer",
  },
  cancelBtn: {
    background: "#ccc",
    border: "none",
    borderRadius: "4px",
    padding: "0.5rem 1rem",
    cursor: "pointer",
  },
};

export default BusinessHeader;
