import Button from "../../components/Button/Button";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { register } from "../../services/authService";
import "./Cadastro.css";

const Cadastro = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const data = await register(name, email, password);
      console.log("Usuário cadastrado:", data);
      // Redirecione o usuário após o cadastro
      navigate("/login");
    } catch (err) {
      setError(err.message || "Erro ao cadastrar");
    }
  };

  return (
    <div className="cadastro-container">
      <h2>Crie sua conta</h2>
      {error && <p className="error-message">{error}</p>}
      <form className="cadastro-form" onSubmit={handleRegister}>
        <label>Nome</label>
        <input
          type="text"
          placeholder="Digite seu nome"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <label>Email</label>
        <input
          type="email"
          placeholder="Digite seu email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label>Senha</label>
        <input
          type="password"
          placeholder="Digite sua senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <Button text="Cadastrar-se" type="submit" variant="secondary" />
      </form>
    </div>
  );
};

export default Cadastro;
