import React, { useState } from "react";
import NotificationPreferencesModal from "../../components/NotificationPreferencesModal/NotificationPreferencesModal";
import "./Notifications.css";

const Notifications = () => {
  // Mock de notificações
  const [notifications, setNotifications] = useState([
    {
      id: 1,
      type: "promo",
      title: "Desconto de 20% na próxima visita!",
      message: "Aproveite a promoção válida até 15/12/2023.",
      date: "2023-12-07 10:30",
      read: false,
    },
    {
      id: 2,
      type: "reminder",
      title: "Lembrete de Agendamento",
      message: "Você tem um agendamento amanhã às 15:30.",
      date: "2023-12-06 14:00",
      read: false,
    },
    {
      id: 3,
      type: "update",
      title: "Novo Serviço Disponível",
      message: "Agora oferecemos tratamentos capilares premium.",
      date: "2023-12-05 12:15",
      read: true,
    },
  ]);

  const [filter, setFilter] = useState("all"); // Filtro ativo
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userPreferences, setUserPreferences] = useState({
    promo: true,
    reminder: true,
    update: true,
    method: "push",
  });

  // Funções para abrir/fechar modal
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  // Salvar preferências de notificação
  const handleSavePreferences = (preferences) => {
    setUserPreferences(preferences);
    console.log("Preferências salvas:", preferences);
  };

  // Filtrar notificações por tipo
  const filteredNotifications = notifications.filter((notification) =>
    filter === "all" ? true : notification.type === filter
  );

  // Marcar notificação como lida
  const markAsRead = (id) => {
    setNotifications((prev) =>
      prev.map((n) => (n.id === id ? { ...n, read: true } : n))
    );
  };

  // Excluir notificação
  const deleteNotification = (id) => {
    setNotifications((prev) => prev.filter((n) => n.id !== id));
  };

  return (
    <div className="notifications-container">
      {/* Cabeçalho da página */}
      <header className="notifications-header">
        <h1>Notificações</h1>
        <button className="settings-button" onClick={handleOpenModal}>
          ⚙️ Preferências
        </button>
      </header>

      {/* Modal de Preferências */}
      <NotificationPreferencesModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSave={handleSavePreferences}
      />

      {/* Filtros de notificações */}
      <div className="notifications-filters">
        <button
          className={`filter-button ${filter === "all" ? "active" : ""}`}
          onClick={() => setFilter("all")}
        >
          Todas
        </button>
        <button
          className={`filter-button ${filter === "promo" ? "active" : ""}`}
          onClick={() => setFilter("promo")}
        >
          Promoções
        </button>
        <button
          className={`filter-button ${filter === "reminder" ? "active" : ""}`}
          onClick={() => setFilter("reminder")}
        >
          Lembretes
        </button>
        <button
          className={`filter-button ${filter === "update" ? "active" : ""}`}
          onClick={() => setFilter("update")}
        >
          Atualizações
        </button>
      </div>

      {/* Lista de notificações */}
      <div className="notifications-list">
        {filteredNotifications.map((notification) => (
          <div
            key={notification.id}
            className={`notification-card ${notification.read ? "read" : ""}`}
          >
            <div className="notification-content">
              <h3>{notification.title}</h3>
              <p>{notification.message}</p>
              <span className="notification-date">{notification.date}</span>
            </div>
            <div className="notification-actions">
              {!notification.read && (
                <button
                  className="mark-as-read"
                  onClick={() => markAsRead(notification.id)}
                >
                  Marcar como Lida
                </button>
              )}
              <button
                className="delete-notification"
                onClick={() => deleteNotification(notification.id)}
              >
                Excluir
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Notifications;
