import React from "react";
import Avatar from "@mui/material/Avatar";
import axios from "axios";

const ProfilePictureUpload = ({ user, updatePicture }) => {
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      const formData = new FormData();
      formData.append("profilePicture", file);

      // Endpoint para upload de foto
      const response = await axios.post(
        `/users/upload-picture/${user.id}`,
        formData
      );
      updatePicture(response.data.profilePicture);
      alert("Profile picture updated!");
    } catch (error) {
      alert("Failed to update profile picture.");
    }
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <label htmlFor="upload-picture" style={{ cursor: "pointer" }}>
        <Avatar
          alt={user.name}
          src={user.profilePicture || "/default-avatar.png"}
          sx={{
            width: 150,
            height: 150,
            border: "3px solid #007bff",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            transition: "box-shadow 0.3s ease",
            "&:hover": { boxShadow: "0 8px 15px rgba(0, 0, 0, 0.3)" },
          }}
        />
      </label>
      <input
        id="upload-picture"
        type="file"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      <p style={{ fontSize: "14px", color: "#555", marginTop: "10px" }}>
        Click na foto para subir uma nova
      </p>
    </div>
  );
};

export default ProfilePictureUpload;
