import React, { useState, useEffect, useRef } from "react";
import { FaStore, FaPlus, FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom"; // Hook para redirecionamento
import { getAllBusinesses } from "../../services/businessService"; // Importar serviço
import "./BusinessMenu.css";

const BusinessMenu = () => {
  const navigate = useNavigate(); // Hook para redirecionamento
  const [isOpen, setIsOpen] = useState(false);
  const [businesses, setBusinesses] = useState([]); // Inicializar vazio
  const dropdownRef = useRef(null);

  // Abre ou fecha o menu
  const toggleMenu = () => setIsOpen(!isOpen);

  // Fecha o menu ao clicar fora
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Buscar todos os negócios do backend ao montar o componente
  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        const businessList = await getAllBusinesses();
        setBusinesses(businessList); // Atualiza o estado com os negócios
      } catch (error) {
        console.error("Erro ao buscar negócios:", error);
      }
    };

    fetchBusinesses();
  }, []);

  // Callback para criar novo negócio
  const handleCreateBusiness = () => {
    navigate("/business/create"); // Redirecionar para a rota de criação do novo negócio
    setIsOpen(false);
  };

  // Callback para selecionar negócio
  const handleSelectBusiness = (businessId) => {
    const selectedBusiness = businesses.find(
      (business) => business.id === businessId
    );
    if (selectedBusiness) {
      setBusinesses((prev) =>
        prev.map((business) => ({
          ...business,
          selected: business.id === businessId,
        }))
      );
      navigate(`/business/${businessId}`); // Redirecionar para a página do negócio selecionado
    }
    setIsOpen(false);
  };

  return (
    <div className="business-menu" ref={dropdownRef}>
      <button className="menu-button" onClick={toggleMenu}>
        <FaStore size={24} />
      </button>
      {isOpen && (
        <div className="dropdown">
          <div className="menu-header clickable" onClick={handleCreateBusiness}>
            <FaPlus className="header-icon" />
            <span className="create-business-text">Criar Novo Negócio</span>
          </div>
          <hr />
          {businesses.length > 0 ? (
            <ul className="business-list">
              {businesses.map((business) => (
                <li
                  key={business.id}
                  className={`business-item ${
                    business.selected ? "selected" : ""
                  }`}
                  onClick={() => handleSelectBusiness(business.id)}
                >
                  <div className="business-info">
                    <h4>{business.name}</h4>
                    <p>{business.email || "Sem email disponível"}</p>
                  </div>
                  {business.selected && (
                    <FaCheckCircle className="selected-icon" />
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p className="no-business">Nenhum negócio encontrado.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default BusinessMenu;
