import api from "./api";

export const login = async (email, password) => {
  try {
    console.log("BaseURL usada:", api.defaults.baseURL);

    await api.post("/auth/login", { email, password });
  } catch (error) {
    const errorMessage = error.response?.data?.message || "Login failed";
    console.error("Login Error:", errorMessage);
    throw new Error(errorMessage);
  }
};

export const getProfile = async () => {
  try {
    const response = await api.get("/users/me");
    return response.data;
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to fetch profile";
    console.error("Get Profile Error:", errorMessage);
    throw new Error(errorMessage);
  }
};

export const updateProfile = async (userId, updatedData) => {
  try {
    const response = await api.put(`/users/put/${userId}`, updatedData);
    return response.data; // Retorna os dados atualizados do usuário
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || "Failed to update profile";
    console.error("Update Profile Error:", errorMessage);
    throw new Error(errorMessage);
  }
};

export const register = async (name, email, password) => {
  try {
    const response = await api.post("/users/register", {
      name,
      email,
      password,
    });
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || "Registration failed";
    console.error("Registration Error:", errorMessage);
    throw new Error(errorMessage);
  }
};
