import React, { useState } from "react";
import "./PaymentSettings.css";

const PaymentSettings = () => {
  const [paymentMethods, setPaymentMethods] = useState([
    { id: 1, type: "Cartão de Crédito", lastDigits: "1234", isDefault: true },
    { id: 2, type: "Pix", key: "user@email.com", isDefault: false },
  ]);

  const [newMethod, setNewMethod] = useState({ type: "", details: "" });

  const handleAddPaymentMethod = () => {
    if (newMethod.type && newMethod.details) {
      setPaymentMethods([
        ...paymentMethods,
        {
          id: paymentMethods.length + 1,
          type: newMethod.type,
          lastDigits: newMethod.details,
          isDefault: false,
        },
      ]);
      setNewMethod({ type: "", details: "" });
    } else {
      alert("Por favor, preencha todos os campos antes de adicionar.");
    }
  };

  const handleRemoveMethod = (id) => {
    setPaymentMethods(paymentMethods.filter((method) => method.id !== id));
  };

  const handleSetDefault = (id) => {
    setPaymentMethods(
      paymentMethods.map((method) =>
        method.id === id
          ? { ...method, isDefault: true }
          : { ...method, isDefault: false }
      )
    );
  };

  return (
    <div className="payment-settings-container">
      <h2>Configurações de Pagamento</h2>
      <div className="payment-methods-list">
        {paymentMethods.map((method) => (
          <div
            key={method.id}
            className={`payment-method ${
              method.isDefault ? "default-method" : ""
            }`}
          >
            <div>
              <h4>{method.type}</h4>
              <p>
                {method.type === "Cartão de Crédito"
                  ? `•••• ${method.lastDigits}`
                  : method.key || method.lastDigits}
              </p>
            </div>
            <div className="method-actions">
              {!method.isDefault && (
                <button onClick={() => handleSetDefault(method.id)}>
                  Definir como Padrão
                </button>
              )}
              <button onClick={() => handleRemoveMethod(method.id)}>
                Remover
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="add-payment-method">
        <h3>Adicionar Método de Pagamento</h3>
        <select
          value={newMethod.type}
          onChange={(e) => setNewMethod({ ...newMethod, type: e.target.value })}
        >
          <option value="">Selecione um tipo</option>
          <option value="Cartão de Crédito">Cartão de Crédito</option>
          <option value="Pix">Pix</option>
        </select>
        <input
          type="text"
          placeholder="Detalhes (últimos dígitos ou chave Pix)"
          value={newMethod.details}
          onChange={(e) =>
            setNewMethod({ ...newMethod, details: e.target.value })
          }
        />
        <button onClick={handleAddPaymentMethod}>Adicionar</button>
      </div>
    </div>
  );
};

export default PaymentSettings;
