import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import Card from "../../components/Cards/Card";
import "./Dashboard.css";

const Dashboard = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  if (!user) return <p>Carregando...</p>;

  return (
    <div className="dashboard-container">
      <h1 className="dashboard-title">Bem-vindo, {user?.name || "Usuário"}!</h1>
      <p className="dashboard-subtitle">Esta é a sua área pessoal.</p>
      <div className="dashboard">
        <Card
          icon="📅"
          title="Próximos Agendamentos"
          content="Você tem 2 agendamentos hoje."
          onClick={() => navigate("/agendamentos")}
        />
        <Card
          icon="🔔"
          title="Notificações"
          content="3 notificações não lidas."
          onClick={() => navigate("/notificacoes")}
        />
        <Card
          icon="⚙️"
          title="Perfil"
          content="Gerencie suas informações pessoais."
          onClick={() => navigate("/perfil")}
        />
      </div>
    </div>
  );
};

export default Dashboard;
