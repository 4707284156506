import React, { useState, useEffect } from "react";
import "./LanguageSettings.css";

const LanguageSettings = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("pt-BR"); // Idioma padrão
  const [selectedRegion, setSelectedRegion] = useState("Brasil"); // Região padrão
  const [loading, setLoading] = useState(true); // Simula carregamento inicial
  const [error, setError] = useState(null); // Simula erro no consumo da API

  const languages = [
    { code: "pt-BR", name: "Português (Brasil)" },
    { code: "en-US", name: "English (United States)" },
    { code: "es-ES", name: "Español (España)" },
    { code: "fr-FR", name: "Français (France)" },
  ];

  const regions = ["Brasil", "Estados Unidos", "Espanha", "França"];

  // Simula o consumo da API para carregar configurações iniciais
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      // Simula um cenário onde dados são carregados do backend
      try {
        setSelectedLanguage("en-US");
        setSelectedRegion("Estados Unidos");
        setLoading(false);
      } catch (err) {
        setError("Erro ao carregar configurações.");
        setLoading(false);
      }
    }, 2000); // Simula atraso de 2 segundos
  }, []);

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value);
  };

  const handleSaveSettings = async () => {
    setLoading(true);
    try {
      // Simula uma chamada ao backend para salvar configurações
      await new Promise((resolve) => setTimeout(resolve, 1500)); // Simula atraso de 1,5s
      alert(
        `Configurações salvas com sucesso: Idioma - ${selectedLanguage}, Região - ${selectedRegion}`
      );
      setLoading(false);
    } catch (err) {
      setError("Erro ao salvar configurações.");
      setLoading(false);
    }
  };

  if (loading) return <p>Carregando configurações...</p>;
  if (error) return <p className="error">{error}</p>;

  return (
    <div className="language-settings-container">
      <h2>Configurações de Idioma e Região</h2>
      <div className="settings-group">
        <label htmlFor="language-select">Idioma:</label>
        <select
          id="language-select"
          value={selectedLanguage}
          onChange={handleLanguageChange}
        >
          {languages.map((language) => (
            <option key={language.code} value={language.code}>
              {language.name}
            </option>
          ))}
        </select>
      </div>
      <div className="settings-group">
        <label htmlFor="region-select">Região:</label>
        <select
          id="region-select"
          value={selectedRegion}
          onChange={handleRegionChange}
        >
          {regions.map((region) => (
            <option key={region} value={region}>
              {region}
            </option>
          ))}
        </select>
      </div>
      <button
        className="save-button"
        onClick={handleSaveSettings}
        disabled={loading}
      >
        {loading ? "Salvando..." : "Salvar Configurações"}
      </button>
    </div>
  );
};

export default LanguageSettings;
