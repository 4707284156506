import React, { useState } from "react";
import NotificationPreferences from "../../components/UserSettingsComponents/NotificationPreferences/NotificationPreferences";
import PrivacySettings from "../../components/UserSettingsComponents/PrivacySettings/PrivacySettings";
import AccountSettings from "../../components/UserSettingsComponents/AccountSettings/AccountSettings";
import LanguageSettings from "../../components/UserSettingsComponents/LanguageSettings/LanguageSettings";
import PaymentSettings from "../../components/UserSettingsComponents/PaymentSettings/PaymentSettings";
import SecuritySettings from "../../components/UserSettingsComponents/SecuritySettings/SecuritySettings";
import GeolocationSettings from "../../components/GeolocationSettings/GeolocationSettings";
import "./UserSettings.css";

const UserSettings = () => {
  const [activeSection, setActiveSection] = useState("notifications");

  const renderSection = () => {
    switch (activeSection) {
      case "notifications":
        return <NotificationPreferences />;
      case "privacy":
        return <PrivacySettings />;
      case "account":
        return <AccountSettings />;
      case "language":
        return <LanguageSettings />;
      case "payment":
        return <PaymentSettings />;
      case "security":
        return <SecuritySettings />;
      case "geolocation":
        return <GeolocationSettings />;
      default:
        return <NotificationPreferences />;
    }
  };

  return (
    <div className="user-settings-container">
      <div className="settings-sidebar">
        <button
          className={`sidebar-button ${
            activeSection === "notifications" ? "active" : ""
          }`}
          onClick={() => setActiveSection("notifications")}
        >
          Notificações
        </button>
        <button
          className={`sidebar-button ${
            activeSection === "privacy" ? "active" : ""
          }`}
          onClick={() => setActiveSection("privacy")}
        >
          Privacidade
        </button>
        <button
          className={`sidebar-button ${
            activeSection === "account" ? "active" : ""
          }`}
          onClick={() => setActiveSection("account")}
        >
          Conta
        </button>
        <button
          className={`sidebar-button ${
            activeSection === "language" ? "active" : ""
          }`}
          onClick={() => setActiveSection("language")}
        >
          Idioma e Região
        </button>
        <button
          className={`sidebar-button ${
            activeSection === "payment" ? "active" : ""
          }`}
          onClick={() => setActiveSection("payment")}
        >
          Pagamento
        </button>
        <button
          className={`sidebar-button ${
            activeSection === "security" ? "active" : ""
          }`}
          onClick={() => setActiveSection("security")}
        >
          Segurança
        </button>
        <button
          className={`sidebar-button ${
            activeSection === "geolocation" ? "active" : ""
          }`}
          onClick={() => setActiveSection("geolocation")}
        >
          Localização
        </button>
      </div>
      <div className="settings-content">{renderSection()}</div>
    </div>
  );
};

export default UserSettings;
