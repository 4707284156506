// mvp\front_web\linka\src\components\Sidebar\Sidebar.jsx
import React from "react";
import { Link } from "react-router-dom";
import "./Sidebar.css";

const Sidebar = ({ isOpen, toggleSidebar, user }) => {
  if (!user) return null;

  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <button className="close-btn" onClick={toggleSidebar}>
        ×
      </button>
      <div className="sidebar-links">
        <Link to="/" className="sidebar-link" onClick={toggleSidebar}>
          Home
        </Link>
        <Link
          to="/agendamentos"
          className="sidebar-link"
          onClick={toggleSidebar}
        >
          Agendamentos
        </Link>
        <Link
          to="/notificacoes"
          className="sidebar-link"
          onClick={toggleSidebar}
        >
          Notificações
        </Link>
        <Link to="/perfil" className="sidebar-link" onClick={toggleSidebar}>
          Perfil
        </Link>
        <Link to="/settings" className="sidebar-link" onClick={toggleSidebar}>
          Configurações
        </Link>
        <Link to="/meuplano" className="sidebar-link" onClick={toggleSidebar}>
          Meu Plano
        </Link>
        <Link to="/historico" className="sidebar-link" onClick={toggleSidebar}>
          Histórico
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
