import React, { useState } from "react";
import {
  MapContainer,
  TileLayer,
  Circle,
  Marker,
  useMapEvents,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";

const GeolocationSettings = () => {
  const [radius, setRadius] = useState(10 * 1000); // Raio em metros
  const [position, setPosition] = useState({ lat: -23.55052, lng: -46.633308 }); // Localização inicial (São Paulo)

  // Função para lidar com o clique no mapa para reposicionar o centro do raio
  const MapClickHandler = () => {
    useMapEvents({
      click: (e) => {
        setPosition(e.latlng);
      },
    });
    return null;
  };

  const handleRadiusChange = (event) => {
    setRadius(event.target.value * 1000); // Converter para metros
  };

  const handleSave = () => {
    console.log(`Raio: ${radius / 1000} km`);
    console.log(`Posição: ${JSON.stringify(position)}`);
    // Integre com o backend para salvar as configurações
  };

  return (
    <div className="geolocation-session">
      <h2>Configuração de Geolocalização</h2>
      <p>Defina o raio máximo para serviços e notificações.</p>
      <div style={{ marginBottom: "10px" }}>
        <label htmlFor="radius">
          Raio de Alcance (km):
          <input
            id="radius"
            type="range"
            min="1"
            max="100"
            value={radius / 1000} // Converter para km
            onChange={handleRadiusChange}
            style={{ marginLeft: "10px", width: "80px" }}
          />
        </label>
      </div>

      {/* Mapa interativo */}
      <MapContainer center={position} zoom={13} className="leaflet-container">
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={position}></Marker>
        <Circle
          center={position}
          radius={radius}
          pathOptions={{ fillColor: "blue", color: "blue" }}
        />
        <MapClickHandler />
      </MapContainer>

      {/* Botão de salvar */}
      <button
        onClick={handleSave}
        style={{
          marginTop: "15px",
          padding: "10px 20px",
          backgroundColor: "#6c63ff",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        Salvar
      </button>
    </div>
  );
};

export default GeolocationSettings;
