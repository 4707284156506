import React, { useState, useRef, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import "./MeuPlano.css";

const MeuPlano = () => {
  const { user } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const modalRef = useRef(null);

  const userPlans = [
    { id: 1, name: "Plano Premium", price: "49,90", status: "Pago" },
  ];

  const userSubscriptions = [
    {
      id: 1,
      business: "Hair's Cortes",
      plan: "Plano Básico",
      price: "19,90",
      benefits: ["Corte Básico Mensal", "Desconto em Produtos"],
      nextPayment: "2023-12-15",
    },
    {
      id: 2,
      business: "VCmaromba",
      plan: "Plano Premium",
      price: "79,90",
      benefits: ["Treino Online", "Consultoria Semanal"],
      nextPayment: "2023-12-20",
    },
    {
      id: 3,
      business: "Meu Pastel",
      plan: "Plano Família",
      price: "39,90",
      benefits: ["3 Pastéis Grátis por Mês", "1 Refrigerante Grande"],
      nextPayment: "2023-12-10",
    },
  ];

  const linkaPlans = [
    {
      id: 1,
      name: "Plano Básico",
      price: "19,90",
      benefits: ["Benefício A", "Benefício B"],
    },
    {
      id: 2,
      name: "Plano Avançado",
      price: "39,90",
      benefits: ["Benefício X", "Benefício Y", "Benefício Z"],
    },
  ];

  const handleOpenModal = (plan) => {
    setSelectedPlan(plan);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedPlan(null);
    setIsModalOpen(false);
  };

  const handleCancelPlan = (plan) => {
    console.log(`Plano cancelado: ${plan.name || plan.plan}`);
    alert(`Plano "${plan.name || plan.plan}" cancelado com sucesso.`);
    handleCloseModal();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleCloseModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  if (!user) {
    return (
      <div className="meu-plano-container">
        <h2>Por favor, faça login para acessar seus planos.</h2>
      </div>
    );
  }

  return (
    <div className="meu-plano-container">
      {/* Menu Lateral */}
      <aside className="plano-sidebar">
        <h3>Minhas Assinaturas</h3>
        <ul className="subscription-list">
          {userSubscriptions.map((sub) => (
            <li
              key={sub.id}
              onClick={() => handleOpenModal(sub)}
              className="subscription-item"
            >
              <p>{sub.business}</p>
              <span>
                {sub.plan} - R$ {sub.price}
              </span>
            </li>
          ))}
        </ul>
        <div className="progress-bar">
          <h4>Progresso do Plano Linka</h4>
          <div className="progress">
            <div className="progress-fill" style={{ width: "75%" }}></div>
          </div>
        </div>
      </aside>

      {/* Conteúdo Principal */}
      <div className="main-content">
        <h2>Meu Plano</h2>

        {/* Plano Atual */}
        {userPlans.length > 0 ? (
          <div className="plan-section">
            <h3>Plano Atual</h3>
            {userPlans.map((plan) => (
              <div key={plan.id} className="plan-card">
                <h4>{plan.name}</h4>
                <p>Preço: R$ {plan.price}/mês</p>
                <p>Status: {plan.status}</p>
                <div className="actions">
                  <button
                    onClick={() => handleOpenModal(plan)}
                    className="details-button"
                  >
                    Detalhes
                  </button>
                  <button
                    onClick={() => handleCancelPlan(plan)}
                    className="cancel-button"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>Você ainda não possui um plano ativo no Linka.</p>
        )}

        {/* Planos Disponíveis */}
        <div className="available-plans">
          <h3>Planos Disponíveis</h3>
          {linkaPlans.map((plan) => (
            <div key={plan.id} className="plan-card">
              <h4>{plan.name}</h4>
              <p>Preço: R$ {plan.price}/mês</p>
              <ul>
                {plan.benefits.map((benefit, index) => (
                  <li key={index}>{benefit}</li>
                ))}
              </ul>
              <button className="upgrade-button">Assinar</button>
            </div>
          ))}
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content" ref={modalRef}>
            <h3>Detalhes do Plano</h3>
            <p>
              <strong>Plano Selecionado:</strong>{" "}
              {selectedPlan.name || selectedPlan.plan}
            </p>
            <p>
              <strong>Preço:</strong> R$ {selectedPlan.price}/mês
            </p>
            {selectedPlan.benefits && (
              <>
                <p>
                  <strong>Benefícios:</strong>
                </p>
                <ul>
                  {selectedPlan.benefits.map((benefit, index) => (
                    <li key={index}>{benefit}</li>
                  ))}
                </ul>
              </>
            )}
            <p>
              <strong>Próxima Cobrança:</strong> {selectedPlan.nextPayment}
            </p>
            <div className="modal-actions">
              <button onClick={handleCloseModal} className="close-button">
                Fechar
              </button>
              <button
                onClick={() => handleCancelPlan(selectedPlan)}
                className="cancel-button"
              >
                Cancelar Plano
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MeuPlano;
