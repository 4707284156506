// mvp\front_web\linka\src\components\Header\Header.jsx
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
// import api from "../../services/api";
import Logo from "../Logo/Logo";
import { useAuth } from "../../contexts/AuthContext";
import BusinessMenu from "../BusinessMenu/BusinessMenu";
// import UserActions from "../UserActions/UserActions";
import Sidebar from "../Sidebar/Sidebar";
import "./Header.css";

const Header = () => {
  const { user, logout } = useAuth();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // const [notifications, setNotifications] = useState([
  //   { id: 1, read: false },
  //   { id: 2, read: false },
  //   { id: 3, read: true },
  // ]);
  // const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const [notifications, setNotifications] = useState([
    {
      id: 1,
      title: "Promoção",
      message: "20% de desconto na sua próxima visita!",
      read: false,
    },
    {
      id: 2,
      title: "Lembrete",
      message: "Você tem um agendamento amanhã às 15h.",
      read: true,
    },
    {
      id: 3,
      title: "Atualização",
      message: "Novo serviço disponível: Tratamento facial!",
      read: false,
    },
  ]);

  const unreadCount = notifications.filter((n) => !n.read).length;
  const dropdownRef = useRef(null);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);

    // Reseta a contagem de notificações não lidas ao abrir o dropdown
    if (!isDropdownOpen) {
      setNotifications((prev) =>
        prev.map((notification) => ({ ...notification, read: true }))
      );
    }
  };

  // const handleLogout = async () => {
  //   try {
  //     await api.post("/auth/logout"); // Faz o logout no backend
  //     logout(); // Atualiza o estado global
  //   } catch (error) {
  //     console.error("Erro ao sair:", error);
  //   }
  // };

  // Fecha o dropdown ao clicar fora dele
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false); // Fecha o dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <>
      <header className="header">
        <div className="header-container">
          {user ? (
            <button
              className={`hamburger ${isSidebarOpen ? "open" : ""}`}
              onClick={toggleSidebar}
            >
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </button>
          ) : (
            <></>
          )}

          <Link to="/" className="logo-link">
            <Logo />
          </Link>

          {/* Central Search */}
          <div className="header-search">
            <input
              type="text"
              placeholder="O que você está procurando?"
              className="search-bar"
            />
          </div>
          <nav>
            {user ? (
              <>
                <div className="user-info">
                  <span>Bem-vindo, {user.name}!</span>
                  {/* Ícone de Notificações */}
                  <div
                    className="notification-icon-container"
                    ref={dropdownRef}
                  >
                    <div className="notification-icon" onClick={toggleDropdown}>
                      <i className="bell-icon">🔔</i>
                      {unreadCount > 0 && (
                        <span className="notification-badge">
                          {unreadCount}
                        </span>
                      )}
                    </div>
                    {/* Dropdown de notificações */}
                    {isDropdownOpen && (
                      <div className="notification-dropdown">
                        <h3>Notificações</h3>
                        {notifications.length > 0 ? (
                          notifications.map((notification) => (
                            <div
                              key={notification.id}
                              className={`notification-item ${
                                notification.read ? "read" : "unread"
                              }`}
                            >
                              <h4>{notification.title}</h4>
                              <p>{notification.message}</p>
                            </div>
                          ))
                        ) : (
                          <p className="no-notifications">
                            Nenhuma notificação no momento.
                          </p>
                        )}
                        <Link to="/notificacoes" className="view-all-link">
                          Ver todas as notificações
                        </Link>
                      </div>
                    )}
                  </div>
                  <div className="header-container">
                    {/* <h1>Meu Aplicativo</h1> */}
                    {/* Insira o BusinessMenu no Header */}
                    <BusinessMenu />
                  </div>
                </div>
                {/* <UserActions user={user} onLogout={handleLogout} /> */}
              </>
            ) : (
              <>
                <Link to="/login" className="nav-link">
                  Login
                </Link>
                <Link to="/cadastro" className="nav-link">
                  Cadastro
                </Link>
              </>
            )}
          </nav>
        </div>
      </header>

      {/* Overlay aparece quando a sidebar está aberta */}
      <div
        className={`overlay ${isSidebarOpen ? "show" : ""}`}
        onClick={toggleSidebar}
      ></div>

      <Sidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        user={user}
      />
    </>
  );
};

export default Header;
